
import { disableNetwork, enableNetwork } from "@/store/firebase"; 
import p from '@/../package.json'
import _cloneDeep from 'lodash/cloneDeep'
import { Device } from '@capacitor/device';
import LogRocket from 'logrocket';
import OutsetaTasks from '@/store/outsetaTasks'

export default {
    state: {
      version: String(p.release_version).split('.'),
      sidebar: true,
      offline: false,
      org: false,
      unit: false,
      // active service worker instance is stored here for easy access
      sw: {
        active: null,
        waiting: null,
        upgrade: false
      },
      // NAV is to remember session variables. If you want to persist between sessions, use user settings (auth.updateUnitSettings)
      nav: {
        gpsLock: null,
        image: null,
        map: null,
        asset: null,
        parent: null,
        hsType: null,
        imgType: null,
        waiting: false,
        installAvailable: false,
        updateAvailable: false,
        loading: true,
        showThumbs: true,
        showReports: true,
        showTour: false,
        tour: false,
      },
      // FORM is for prefilling forms between components. If you want to remember things, use NAV
      form: {
        image: {},
        asset: {},
        report: {}
      },
      // INITIALISATION STATE
      init: {
        org: false,
        unit: false,
        assets: false,
        images: false,
        files: false,
        complete: false,
        failed: false,
      },
      imageCache: [],
      rootCache: [],
      alert: {
        notice: null,
        variant: 'info',
        timeout: 5000,
        timer: null
      },
      device: {},
      pdfToEdit: {},
      newPdfUploadEvent: null
    },
    getters: {
      cacheList: (state) => {
        let imageCache = state.imageCache || []
        let fileCache = state.fileCache || []
        let cacheList = imageCache.concat(fileCache)
        return cacheList
      }
    },
    mutations: {
      SET_INIT(state, init) {
        if(init == null) {
          state.init = {
            org: false,
            unit: false,
            assets: false,
            images: false,
            files: false,
            complete: false,
            failed: false,
          }   
        }
        else {
          if(init.org) state.init.org = init.org;
          if(init.unit) state.init.unit = init.unit;
          if(init.assets) state.init.assets = init.assets;
          if(init.images) state.init.images = init.images;
          if(init.files) state.init.files = init.files;
          if(init.complete) state.init.complete = init.complete;
          if(init.failed) state.init.failed = init.failed;
        }
      },
      SET_ORG(state, id) {
        state.org = id
      },
      SET_UNIT(state, id) {
        state.unit = id
      },
      SET_SIDEBAR(state, value) {
        state.sidebar = value
      },
      SET_ALERT(state, alert) {
        clearTimeout(state.alert.timer)
        if(typeof alert === 'string' || !alert) alert = { notice: alert || null }
        state.alert = {
          notice: alert.notice || null,
          variant: alert.variant || 'info',
          timeout: alert.timeout || 5000
        }
        state.alert.timer = setTimeout(() => { state.alert.notice = null }, state.alert.timeout)
      },
      SET_FORM(state, { form, data }) {
        if (!['image', 'asset', 'report'].includes(form)) return console.warn('invalid parameters')
        state.form[form] = data
      },
      SET_NAV(state, nav) {
        // console.warn('setNav', nav)
        if (typeof nav.gpsLock !== 'undefined') state.nav.gpsLock = nav.gpsLock
        if (typeof nav.image !== 'undefined') state.nav.image = nav.image
        if (typeof nav.imgType !== 'undefined') state.nav.imgType = nav.imgType
        if (typeof nav.asset !== 'undefined') state.nav.asset = nav.asset
        if (typeof nav.map !== 'undefined') state.nav.map = nav.map
        if (typeof nav.parent !== 'undefined') state.nav.parent = nav.parent
        if (typeof nav.hsType !== 'undefined') state.nav.hsType = nav.hsType
        if (typeof nav.waiting !== 'undefined') state.nav.waiting = nav.waiting
        if (typeof nav.showThumbs !== 'undefined') state.nav.showThumbs = nav.showThumbs
        if (typeof nav.showReports !== 'undefined') state.nav.showReports = nav.showReports
        if (typeof nav.tour !== 'undefined') {
          if(nav.tour) state.nav.showTour = true
          state.nav.tour = nav.tour
        }
        // console.log(state.nav.parent, state.nav.asset)
      },
      SET_ROOT_CACHE(state, rootCache) {
        state.rootCache = rootCache
      },
      SET_INSTALL_AVAIL(state, value) {
        state.installAvailable = value
      },
      SET_SW(state, value) {
        state.sw.active = value.active
        state.sw.waiting = value.waiting
        if(value.waiting) state.sw.upgrade = true
      },
      SET_LOADING(state, value) {
        state.nav.loading = value;
      },
      SET_OFFLINE(state, value) {
        state.offline = value;
      },
      SET_IMAGECACHE(state, value) {
        state.imageCache = value;
      },
      SET_PDF_TO_EDIT(state, pdf) {
        state.pdfToEdit = pdf
      },
      SET_NEW_PDF_UPLOAD_EVENT(state, pdf) {
        state.newPdfUploadEvent = pdf
      },
      SET_DEVICE(state, device) {
        state.device = device
      }
    },
    actions: {
      async initDevice({ commit }) { 
        // test for touch device
        let touch = false
        if ('ontouchstart' in window) touch = true
        if (navigator.msMaxTouchPoints) touch = true
        let userAgent = navigator.userAgent
        
        const DeviceInfo = await Device.getInfo();
        const DeviceID = await Device.getId();

        let device = {
          uid: DeviceID.identifier,
          os: DeviceInfo.operatingSystem,
          osVersion: DeviceInfo.osVersion,
          platform: DeviceInfo.platform,
          standalone: window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || false,
          mobile: ['ios','android'].includes(DeviceInfo.operatingSystem),
          touch,
          userAgent,
          hostname: window.location.hostname,
          check: new Date()
        }
        const envMode = process.env.NODE_ENV

        if(envMode === 'development') {
          console.log('Outseta disabled in development env')
          // OutsetaTasks.init()
          
          console.log('LogRocket disabled in development env')
          // LogRocket.init('que57j/trakk-assets-dev', {
          //   release: p.version,
          // });
        }
        else if (device.platform !== 'web' || device.hostname.startsWith('app.')) {
          OutsetaTasks.init()
          LogRocket.init('que57j/trakk-assets-production', {
            release: p.version,
          });
        }
        else if (device.hostname.startsWith('apptest.')) {
          OutsetaTasks.init()
          LogRocket.init('que57j/trakk-assets-test', {
            release: p.version,
          });
        }
        commit('SET_DEVICE', device)
      },
      toggleNetwork({ commit, state }) {
        let toggle = async () => {
          if(state.offline) {
            await enableNetwork()
            commit('SET_OFFLINE', false)
            console.log("Network enabled");
          }
          else {
            await disableNetwork();
            commit('SET_OFFLINE', true)
            console.log("Network disabled");
          }
        }
        toggle()
      },
      loading({ commit, rootState, state, dispatch }, value) {
        // console.log('dispatch loading', value)
        if (!state.device.check) dispatch('initDevice')
        if (value === undefined) value = true
        document.getElementById("tk-loader-info").innerHTML = ''
        document.getElementById("tk-loader-version").innerHTML = `v ${state.version[0]}.${state.version[1]}.${state.version[2]}`
        let set = (newValue) => {
          if(newValue) {
            document.getElementById("tk-app-loader").style.display = "flex";
            commit('SET_LOADING', true)
          } else {
            document.getElementById("tk-app-loader").style.display = "none";
            commit('SET_LOADING', false)
          }
        }
        if(typeof value === 'boolean') set(value)
        else if(Array.isArray(value)) {
          set(true)
          let loadStart = Date.now()
          let checks = () => {
            let pass = true
            let update = ''
            if (value.includes('records')) {
              pass = false
              let stage = 'org'
              if(state.init.complete) pass = true
              else if(state.init.images) stage = 'files'
              else if(state.init.assets) stage = 'images'
              else if(state.init.unit) stage = 'assets'
              else if(state.init.org) stage = 'unit'
              update = `Loading ${stage}`
            }
            if(pass) {
              document.getElementById("tk-loader-info").innerHTML = ""
              document.getElementById("tk-loader-logout").style.display = "none";
              set(false)
            }
            else {
              let checkWait = Date.now() - loadStart
              if(update.length > 0) update += '<br />'
              // if(checkWait > 20000) update += '<br />This seems to be taking too long. You might have a network issue.'
              if(checkWait > 60000) {
                update += '<br />Initial data load can take long time, please be patient...'
                document.getElementById("tk-loader-logout").style.display = "block";
              }
              else if(checkWait > 30000) update += '<br />This can take a while.'
              else if(checkWait > 20000) update += '<br />Still going...'
              else if(checkWait > 10000) update += '<br />Caching your data...'
              document.getElementById("tk-loader-info").innerHTML = update;
              if(state.nav.loading) setTimeout(checks, 1000)
            }
          }
          checks()
        }
        else {
          console.warn('Invalid dispatch => loading')
        }
      },
      nav({commit, state}, nav) {
        commit('SET_NAV', nav)
      },
      switchOrg({ commit, dispatch }, id) {
        if (id === null) id = 'demo'
        dispatch('loading', true)
        commit('SET_ORG', id)
      },
      toggleNav(context) {
        context.commit('switchNav')
      },
      setSidebar({ commit }, value) {
        commit('SET_SIDEBAR', value)
      },
      showInstall({ commit }) {
        console.log('showInstall')
        commit('SET_INSTALL_AVAIL', true)
      },
      pushImageCache({ state, commit }, iid) {
        if(iid) {
          let imageCache = _cloneDeep(state.imageCache || [])
          let existIndex = imageCache.findIndex(i => { return i === iid })
          if(existIndex !== -1) imageCache.splice(existIndex,1)
          imageCache.splice(0,0,iid)
          imageCache = imageCache.slice(0,20)
          commit('SET_IMAGECACHE', imageCache)
        }
      },
      setNewPdfUploadEvent({ commit }, pdf) {
        commit('SET_NEW_PDF_UPLOAD_EVENT', pdf)
      }
    }
  }
  