import p from '@/../package.json'
import { initializeApp } from "firebase/app";
import { fbConfig } from "./firebase-config"
import { getAnalytics, logEvent, setUserProperties, setUserId } from "firebase/analytics";
import { StatsigClient } from '@statsig/js-client'
import { runStatsigSessionReplay } from '@statsig/session-replay';
import { Device } from '@capacitor/device';

import store from "@/store"

// const indexedDB = typeof window == 'object' ? window.indexedDB : webkitIndexedDB;
const fbApp = initializeApp(fbConfig)
const analytics = getAnalytics(fbApp)
setUserProperties(analytics, { app_version: p.release_version });

const statsigOptions = {}

let logToFB = true
let statsig = null

const init = async () => {
    const DeviceInfo = await Device.getInfo();
    const hostname = window.location.hostname
    
    if(DeviceInfo.platform === 'web') {
        if(!hostname.startsWith('app.') && !hostname.startsWith('apptest.')) {
            console.log('FB Analytics disabled in development mode')
            console.log('Statsig logging to development teir')
            logToFB = false
            statsigOptions.environment = { teir: 'development' }
        }
        if(hostname.startsWith('apptest.')) statsigOptions.environment = { teir: 'staging' }
    }
    statsig = new StatsigClient("client-KZEPEWbeccBw2WimjNsva0feV3UMUKNfUJsg1ej6Kqn", {}, statsigOptions);
    runStatsigSessionReplay(statsig);
    statsig.initializeAsync();
}
init()

const LogEvent = (event, parameters) => {
    if(typeof parameters === 'string') parameters = { key: parameters }
    if(!parameters) parameters = {}
    
    // fb analytics
    if(logToFB) {
        let org = store.state.Db.org.id
        logEvent(analytics, event, { org, ...parameters })
    }

    // statsig
    if(statsig) {
        let key = parameters.key || true
        if(event === 'page_view') {
            if(!parameters.page_title) return
            key = parameters.page_title
        }
        if(event === 'use_feature') {
            if(!parameters.feature) return
            key = parameters.feature
        }
        if(event === 'search') {
            if(String(parameters.search_element).trim().length === 0) return
            key = parameters.search_element
        }
        if(event === 'select_item') {
            if(!parameters.item_list_id) return
            key = parameters.item_list_id
        }
        // console.log(event, key, parameters)
        statsig.logEvent(event, key, parameters);
    }
}

const LogSetOrg = async () => {
    let account = store.state.Db.org.account || null
    let release = p.release_version
    let current_org = `${store.state.Db.org.name} (${store.state.Db.org.id})`
    
    // fb analytics
    if(logToFB) {
        setUserProperties(analytics, { current_org });
    }

    // statsig
    if(statsig) {
        try {
            let user = {
                userID: store.state.Auth.configUser.id,
                email: store.state.Auth.configUser.email,
                customIDs: { org: current_org, account, release }
            }
            statsig.updateUserSync(user);
            let gates = store.state.Auth.featureGates
            Object.keys(gates).forEach(key => {
                FeatureGate(key).then(value => {
                    // console.log(key, value)
                    store.commit('SET_GATE', { key, value })
                })
            })
        }
        catch (e) {
            console.log('statsig offline')
        }
    }
}

const LogUserLogin = async (user) => {
    if(!user || !user.uid) return

    // fb analytics
    if(logToFB) {
        setUserId(analytics, user.uid) 
        logEvent(analytics, 'login')
    }

    // statsig
    if(statsig) {
        statsig.updateUserSync({ userID: user.uid })
        statsig.logEvent('refresh', true);
    }
}

const FeatureGate = async (key) => {
    if(!key) return false

    // statsig
    if(statsig) {
        let value = statsig.checkGate(key)
        return value
    }
    return false
}

export {
    LogEvent,
    LogSetOrg,
    LogUserLogin,
    FeatureGate
}
